import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyASYGK75tbcbH124i0C7k5moz-oxxqbrCQ",
  authDomain: "d-viewer-aa877.firebaseapp.com",
  databaseURL: "https://d-viewer-aa877-default-rtdb.firebaseio.com",
  projectId: "d-viewer-aa877",
  storageBucket: "d-viewer-aa877.appspot.com",
  messagingSenderId: "912447617473",
  appId: "1:912447617473:web:be4e2f260f99c4e34ed740",
};

firebase.initializeApp(firebaseConfig);

export default firebase;
