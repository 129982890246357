import styles from "./Modal.module.css";

function Modal(props) {
  return (
    <div>
      {props.isLarge ? (
        <div className={styles.modal}>
          <div className={styles["modal-dialog"]}>
            <div className={styles["modal-body"]}>{props.children}</div>
          </div>
        </div>
      ) : (
        <div className={styles["modal-small"]}>
          <div className={styles["modal-dialog-small"]}>
            <div className={styles["modal-body-small"]}>{props.children}</div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Modal;
