import styles from "./MaccaturoRightBar.module.css";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faSeedling,
  faExclamationTriangle,
  faInfo,
  faArrowAltCircleLeft,
  faBoxOpen,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import Modal from "../../UI/Modal";
import BackDrop from "../../UI/BackDrop";
import i18n from "../../utility/i18n";
import logoAr from "../../../icon/AR.png";
import {useSelector, useDispatch} from 'react-redux';
import {authActions} from '../../utility/redux/lang';

function MaccaturoRightBar(props) {
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSecondModalOpen, setIsSecondModalOpen] = useState(false);
  const dispatch = useDispatch();
  const lang = useSelector(state => state.lang.lang);

  const lista = [];

  function displayModalHandler() {
    setIsModalOpen(!isModalOpen);
  }

  function displaySecondModalHandler() {
    setIsSecondModalOpen(!isSecondModalOpen);
  }

  function changeLanguage(ln) {
    return () => {
      dispatch(authActions.changeLang(ln));
    };
  }

  return (
    <div>
      {isModalOpen && (
        <Modal isLarge={true}>
          <div className={styles["info-container"]}>
            <div className={styles["info-fagiolo-container-one-option"]}>
              <h3 className={styles["modal-title"]}>
                {" "}
                Guarda la box Maccaturo da ogni prospettiva!{" "}
              </h3>
              <div className={styles["image-container"]}>
                <img
                  className={styles["modal-image-two"]}
                  src="https://firebasestorage.googleapis.com/v0/b/d-viewer-aa877.appspot.com/o/img%2FMaccaturo%2Fmaccaturo%20panoramica.jpg?alt=media&token=937828aa-807d-46d8-ac64-5295855994b0"
                  alt="modal img"
                ></img>
              </div>
            </div>
            <div className={styles["info-fagiolo-container-two-option"]}>
              <div className={styles["paragrafo-modale-option"]}>
                <button className={styles["menu-button"]}>
                  Open box{" "}
                  <FontAwesomeIcon icon={faBoxOpen} size="lg" color="eb505d" />
                </button>
                <br></br>Apri la scatola per vedere cosa contiene
              </div>
              <div className={styles["paragrafo-modale-option"]}>
                <button className={styles["menu-button"]}>
                  Show Products <FontAwesomeIcon icon={faEye} size="lg" />
                </button>
                <br></br> Visualizza tutti i prodotti
              </div>
              <div className={styles["paragrafo-modale-option"]}>
                <button className={styles["menu-button"]}>
                  <FontAwesomeIcon icon={faExclamationTriangle} size="xs" />
                </button>
                <br></br> Il Pulsante AR viene visualizzato solo sui dispositivi
                di ultima generazione Usi un dispositivo Android? Lo sai che
                puoi fare l’intera esperienza animata in AR?{" "}
              </div>
              <div className={styles["paragrafo-modale-option"]}>
                <button className={styles["ar-button"]}>
                  <img src={logoAr} alt="ar" />
                </button>
                <br></br>
                Colloca la box Maccaturo su un piano in dimensione reale
                Visualizza in Realtà Aumentata: premi il pulsante AR
              </div>
            </div>
          </div>
        </Modal>
      )}
      {isModalOpen && <BackDrop onCancel={displayModalHandler}></BackDrop>}
      <button className={styles["info-button"]} onClick={displayModalHandler}>
        <FontAwesomeIcon icon={faInfoCircle} size="lg" />
      </button>

      {isSecondModalOpen && (
        <Modal isLarge={true}>
          <div className={styles["info-container"]}>
            <div className={styles["info-fagiolo-container-one"]}>
              {lang === 'en' && <h1 className={styles["modal-title"]}>{props.desc[0].Content_One[0].titolo_en}</h1>}
              {lang === 'it' && <h1 className={styles["modal-title"]}>{props.desc[0].Content_One[0].titolo_it}</h1>}

              {lang === 'en' && <div> 
              <p className={styles["paragrafo-modale"]}>
                {props.desc[0].Content_One[0].desc_en}
              </p>
              </div>}
              {lang === 'it' && <div> 
              <p className={styles["paragrafo-modale"]}>
                {props.desc[0].Content_One[0].desc_it}
              </p>
              </div>}

              {lang === 'en' && <div> 
              <h2 className={styles["modal-sub-title"]}>
                {props.desc[0].Content_Two[0].titolo_en}
              </h2>
              </div>}
              {lang === 'it' && <div> 
              <h2 className={styles["modal-sub-title"]}>
                {props.desc[0].Content_Two[0].titolo_it}
              </h2>
              </div>}

              {lang === 'en' && <div> 
              <div className={styles["paragrafo-modale"]}>
                <h3> {props.desc[0].Content_Two[1].titolo_en} </h3>
                {props.desc[0].Content_Two[1].desc_en}
                <h3>{props.desc[0].Content_Two[2].titolo_en}</h3>
                <p>{props.desc[0].Content_Two[2].desc_en}</p>
              </div>
              </div>}
              {lang === 'it' && <div> 
              <div className={styles["paragrafo-modale"]}>
                <h3> {props.desc[0].Content_Two[1].titolo_it} </h3>
                {props.desc[0].Content_Two[1].desc_it}
                <h3>{props.desc[0].Content_Two[2].titolo_it}</h3>
                <p>{props.desc[0].Content_Two[2].desc_it}</p>
              </div>
              </div>}

                {lang === 'it' && <div>
                <h3>{props.desc[0].Content_Two[3].titolo_it}</h3>
                {props.desc[0].Content_Two[3].tipo === 'lista' ? <ul>{
                  props.desc[0].Content_Two[3].array_it.forEach(element => {
                    lista.push(<li key={Math.random()}>{element}</li>)
                  })}
                  {lista}
                  </ul>
                : <p>{props.desc[0].Content_Two[3].desc_it}</p>}
                </div>}
                {lang === 'en' && <div>
                <h3>{props.desc[0].Content_Two[3].titolo_en}</h3>
                {props.desc[0].Content_Two[3].tipo === 'lista' ? <ul>{
                  props.desc[0].Content_Two[3].array_en.forEach(element => {
                    lista.push(<li key={Math.random()}>{element}</li>)
                  })}
                  {lista}
                  </ul>
                : <p>{props.desc[0].Content_Two[3].desc_en}</p>}
                </div>}


             
            </div>

            <div className={styles["info-fagiolo-container-two"]}>
              <div className={styles["paragrafo-modale"]}>
               

                {lang === 'it' && <div> 
                <h3>{props.desc[0].Content_Two[4].titolo_it}</h3>
                {props.desc[0].Content_Two[4].tipo === 'lista' ? <ul>{
                  props.desc[0].Content_Two[4].array_it.forEach(element => {
                    lista.push(<li key={Math.random()}>{element}</li>)
                  })}
                  {lista}
                  </ul>
                : <p>{props.desc[0].Content_Two[4].desc_it}</p>}
                </div>}
                {lang === 'en' && <div> 
                <h3>{props.desc[0].Content_Two[4].titolo_en}</h3>
                {props.desc[0].Content_Two[4].tipo === 'lista' ? <ul>{
                  props.desc[0].Content_Two[4].array_en.forEach(element => {
                    lista.push(<li key={Math.random()}>{element}</li>)
                  })}
                  {lista}
                  </ul>
                : <p>{props.desc[0].Content_Two[4].desc_en}</p>}
                </div>}

                 {lang === 'it' && <div> 
                <h3>{props.desc[0].Content_Two[5].titolo_it}</h3>
                {props.desc[0].Content_Two[5].tipo === 'lista' ? <ul>{
                  props.desc[0].Content_Two[5].array_it.forEach(element => {
                    lista.push(<li key={Math.random()}>{element}</li>)
                  })}
                  {lista}
                  </ul>
                : <p>{props.desc[0].Content_Two[5].desc_it}</p>}
                </div>}
                {lang === 'en' && <div> 
                <h3>{props.desc[0].Content_Two[5].titolo_en}</h3>
                {props.desc[0].Content_Two[5].tipo === 'lista' ? <ul>{
                  props.desc[0].Content_Two[5].array_en.forEach(element => {
                    lista.push(<li key={Math.random()}>{element}</li>)
                  })}
                  {lista}
                  </ul>
                : <p>{props.desc[0].Content_Two[5].desc_en}</p>}
                </div>}

                {lang === 'it' && <div> 
                <h3>{props.desc[0].Content_Two[6].titolo_it}</h3>
                {props.desc[0].Content_Two[6].tipo === 'lista' ? <ul>{
                  props.desc[0].Content_Two[6].array_it.forEach(element => {
                    lista.push(<li key={Math.random()}>{element}</li>)
                  })}
                  {lista}
                  </ul>
                : <p>{props.desc[0].Content_Two[6].desc_it}</p>}
                </div>}
                {lang === 'en' && <div> 
                <h3>{props.desc[0].Content_Two[6].titolo_en}</h3>
                {props.desc[0].Content_Two[6].tipo === 'lista' ? <ul>{
                  props.desc[0].Content_Two[6].array_en.forEach(element => {
                    lista.push(<li key={Math.random()}>{element}</li>)
                  })}
                  {lista}
                  </ul>
                : <p>{props.desc[0].Content_Two[6].desc_en}</p>}
                </div>}

              </div>
              <button
                className={styles["menu-button-scopri"]}
                onClick={props.numberStep}
              >
                {props.boxTitle}{" "}
                <FontAwesomeIcon icon={faInfo} size="lg" />
              </button>

              <div className={styles["image-container"]}>
                  <img
                    className={styles["modal-image"]}
                    src="https://firebasestorage.googleapis.com/v0/b/d-viewer-aa877.appspot.com/o/img%2FMaccaturo%2FMacc_NERO-(1).png?alt=media&token=e4cee981-b656-4b38-b27e-31b1a31f3e5d"
                    alt="modal img"
                  ></img>
                </div>

                <div className="btn-translate">
                <button onClick={changeLanguage("it")}>IT</button>
                <button onClick={changeLanguage("en")}>EN</button>
              </div>

            </div>
          </div>
        </Modal>
      )}
      {isSecondModalOpen && (
        <BackDrop onCancel={displaySecondModalHandler}> </BackDrop>
      )}
      <button
        className={styles["info-button1"]}
        onClick={displaySecondModalHandler}
      >
        <FontAwesomeIcon icon={faSeedling} size="lg" />
      </button>
    </div>
  );
}

export default MaccaturoRightBar;
