import React from "react";
import styles from "./MaccaturoViewerMenu.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { useTranslation } from "react-i18next";
// import i18n from "../../utility/i18n ";
import {
  faArrowAltCircleLeft,
  faBoxOpen,
  faEye,
  faInfo,
} from "@fortawesome/free-solid-svg-icons";

// i18n.changeLanguage('en');

function MaccaturoViewerMenu(props) {
  //const [isModalOpen, setIsModalOpen] = useState(false);
  //const { t } = useTranslation();

  return (
    <div>
      {props.numberStep === 0 ? (
        <div className={styles["viewer-menu"]}>
          <div className={styles["left-menu"]}></div>

          <div className={styles["center-menu"]}>
            <button
              className={styles["menu-button"]}
              onClick={props.nextGlbModel}
            >
              Open box{" "}
              <FontAwesomeIcon
                icon={faBoxOpen}
                size="lg"
                color="eb505d"
                className={styles["font-icon"]}
              />
            </button>
          </div>
        
          <div className={styles["right-menu"]}></div>

          
        </div>
      ) : null}

      {props.numberStep === 1 ? (
        <div className={styles["viewer-menu"]}>
          <div className={styles["left-menu"]}>
            <button
              className={styles["menu-button-prev"]}
              onClick={props.prevGlbModel}
            >
              <FontAwesomeIcon icon={faArrowAltCircleLeft} size="lg" />
            </button>
          </div>

          <div className={styles["center-menu"]}>
            <button
              className={styles["menu-button"]}
              onClick={props.nextGlbModel}
            >
              SHOW PRODUCTS{" "}
              <FontAwesomeIcon
                icon={faEye}
                size="lg"
                className={styles["font-icon"]}
              />
            </button>
          </div>

         
          <div className={styles["right-menu"]}></div>

               </div>
      ) : null}

      {props.numberStep === 2 ? (
        <div className={styles["viewer-menu"]}>
          <div className={styles["left-menu"]}>
            <button
              className={styles["menu-button-prev"]}
              onClick={props.prevGlbModel}
            >
              <FontAwesomeIcon icon={faArrowAltCircleLeft} size="lg" />
            </button>
          </div>

          {/* <div className={styles["center-menu"]}>
            <button
              className={styles["menu-button"]}
              onClick={props.nextGlbModel}
            >
              {props.buttonStep3}{" "}
              <FontAwesomeIcon
                icon={faInfo}
                size="mg"
                className={styles["font-icon"]}
              />
            </button>
          </div> */}

         
          <div className={styles["right-menu"]}></div>

             </div>
      ) : null}

      {props.numberStep === 3 ? (
        <div className={styles["viewer-menu"]}>
          <div className={styles["left-menu"]}>
            <button
              className={styles["menu-button-prev"]}
              onClick={props.prevGlbModel}
            >
              <FontAwesomeIcon icon={faArrowAltCircleLeft} size="lg" />
            </button>
          </div>

          <div className={styles["center-menu"]}>
          </div>

         
          <div className={styles["right-menu"]}></div>

              </div>
      ) : null}
    </div>
  );
}

export default MaccaturoViewerMenu;
