import { configureStore } from '@reduxjs/toolkit';

import authReducer from './lang';


const store = configureStore({
  reducer: { lang: authReducer },
});


export default store;