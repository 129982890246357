import { useEffect, useState, Fragment } from "react";
import firebase from "../../components/utility/firebase";
import style from "./GiovannaQuaratino.module.css";
import shirtOne from "../../assets/Shirt_bag_1.glb"

/*eslint-disable */

function GiovannaQuaratino(props) {
    
  const [viewerProperties, setViewerProperties] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [desc, setDesc] = useState([]);
  const [arrayGlb, setUrl] = useState([]);
  const [isCorrectViewer, setIsCorrectViewer] = useState(true);
 

  const fireCollection = firebase.firestore().collection("Visualizzatori-3D");

  const MODEL_INSTANCE = {
    src: shirtOne,
    cameraControls: true,
    autoRotate: false, 
    
    // alt: props.alt,
    cameraTarget: "-0.0m -0.0m 0.02m",
    cameraControls:"true",
    // autoRotate: props.autoRotate,
    // disableZoom: props.disableZoom,
     lightExposition:1,
     shadowSmooth:2,
     shadowIntensity: 5,
    // rotationPerSecond: props.rotationPerSecond,
    // animationName: props.animationName,
    // poster: props.poster,
    // ios: props.ios,
    // numberS: props.setNumberStep,
    // scale: props.scale,
    // text: props.text,
  };

  const loadingScreen = <p>Loading...</p>;

  


  

  let customPageFound = !isLoading && isCorrectViewer;

  function camera() {
    if (typeof arrayGlb[numberStep].camera_orbit === 'undefined') {
      arrayGlb[numberStep].camera_orbit = "0deg 40deg 101%";
    }
    return arrayGlb[numberStep].camera_orbit;
  }

  function isFacebookBroswer(){
    let ua = navigator.userAgent || navigator.vendor || window.opera ;
    //return true;
    return (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1);
  }

  const isFirefox = typeof InstallTrigger !== 'undefined';

  return (
    <div className={style.container}>
    <div
    className={style["model-container"]}
    // style={{ backgroundImage: `url(https://ar3d.spaarkly.it/model3d/Bulgari_logo.svg.png)` }}

     >
         <model-viewer
         src={MODEL_INSTANCE.src}
         id = "GiovannaQuaratino-viewer"
    //   ios-src={MODEL_INSTANCE.ios}
         environment-image="https://ar3d.spaarkly.it/model3d/studio_small_08_1k.hdr"
    //   alt={MODEL_INSTANCE.alt}
         camera-controls="true"
        //  auto-rotate={castValues(MODEL_INSTANCE.autoRotate)}
        //  camera-target={cameraTarget}
         exposure={MODEL_INSTANCE.lightExposition}
         shadow-intensity={MODEL_INSTANCE.shadowIntensity}
    //   shadow-sofness={MODEL_INSTANCE.shadowSmooth}
    //   rotation-per-second={MODEL_INSTANCE.rotationPerSecond}
        //  camera-orbit={cameraOrbit}
        //  exposure={exposure}
    //   animation-name={MODEL_INSTANCE.animationName}
         ar
         ar-modes="scene-viewer webxr quick-look"
    //   animation-crossfade-duration="0"
    //   poster={MODEL_INSTANCE.poster}
         loading="eager"
        // xr-environment

    >

    

       

       
    </model-viewer>
    </div>
    </div>
   
);
}

export default GiovannaQuaratino;
