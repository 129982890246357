import { useParams } from "react-router-dom";
import Viewer from "../../pages/Viewer";

function TokenViewer() {
  const { id: idToken } = useParams();

  return (
    <div>
      <Viewer token={idToken}></Viewer>
    </div>
  );
}

export default TokenViewer;
