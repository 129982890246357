import { useEffect, useState, createRef, Fragment } from "react";
import MaccaturoModelViewer from "../components/layout/Maccaturo/MaccaturoModelViewer";
import firebase from "../components/utility/firebase";
import style from "./Viewer.module.css";

/*eslint-disable */

function Viewer(props) {
  const [viewerProperties, setViewerProperties] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [url] = useState("");
  const [isCustom, setIsCustomPage] = useState(false);

  const [animationName] = useState("");

  const modelViewRef = createRef();

  const fireCollection = firebase.firestore().collection("Visualizzatori-3D");

  function getData() {
    fireCollection
      .doc(props.token)
      .get()
      .then((item) => {
        setViewerProperties(item.data());
        isPageCorrect();
        setIsLoading(false);
      });
  }

  useEffect(() => {
    getData();
  }, [getData]);

  const loadingScreen = <p>Loading...</p>;

  function formatString(str) {
    return str.replaceAll(",", "deg ") + "%";
  }

  function isPageCorrect() {
    if (!viewerProperties.custom_viewer) {
      setIsCustomPage(false);
    } else {
      setIsCustomPage(true);
    }
  }

  let customPageFound = !isLoading && isCustom;

  return (
    <div>
      {isLoading && loadingScreen}
      {customPageFound && (
        <Fragment>
          <div className={style.container}>
            <MaccaturoModelViewer
              forwardRef={modelViewRef}
              cameraOrbit={formatString(String(viewerProperties.camera_orbit))}
              url={url}
              alt={viewerProperties.alt}
              autoRotate={viewerProperties.auto_rotate}
              cameraControls={viewerProperties.camera_controls}
              disableZoom={viewerProperties.disable_zoom}
              speedRotate={`${viewerProperties.speed_rotate} deg`}
              lightExposition={viewerProperties.exposure}
              shadowIntensity={viewerProperties.shadow_intensity}
              shadowSmooth={viewerProperties.shadow_softness}
              // getPath={viewerProperties}
              //  autoplay={viewerProperties.autoplay}
              animationName={animationName}
            ></MaccaturoModelViewer>
          </div>
        </Fragment>
      )}
      {!customPageFound && <p>Page not found</p>}
    </div>
  );
}

export default Viewer;
