import { useEffect, useState, Fragment } from "react";
import MaccaturoModelViewer from "../../components/layout/Maccaturo/MaccaturoModelViewer";
import firebase from "../../components/utility/firebase";
import MaccaturoViewerMenu from "../../components/layout/Maccaturo/MaccaturoViewerMenu";
import style from "./Maccaturo.module.css";
import MaccaturoRightBar from "../../components/layout/Maccaturo/MaccaturoRightBar";

/*eslint-disable */

function Maccaturo(props) {
  const [viewerProperties, setViewerProperties] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [desc, setDesc] = useState([]);
  const [arrayGlb, setUrl] = useState([]);
  const [isCorrectViewer, setIsCorrectViewer] = useState(false);
  let [renderCount, setRenderCount] = useState(0);

  const [animationName, setAnimationName] = useState("");
  let [numberStep, setNumberStep] = useState(0);

  const fireCollection = firebase.firestore().collection("Visualizzatori-3D");

  function updateDataView() {
    const docRef = fireCollection.doc(props.token);
    // const number = viewerProperties.view + 1;

    docRef.update({
      view: parseInt(viewerProperties.view++),
    });
  }

  function getSubCollection() {
    fireCollection
      .doc(props.token)
      .collection("3d-model").orderBy("step")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          arrayGlb.push(doc.data());
          setUrl(arrayGlb);
        });
      });
  }

  function getSubCollectionDescription() {
    fireCollection
      .doc(props.token)
      .collection("ModalDesc")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          desc.push(doc.data());
          setDesc(desc);
        });
      });
  }

  useEffect(() => {
    const getData = () => {
      fireCollection
        .doc(props.token)
        .get()
        .then((item) => {
          setViewerProperties(item.data());
        });
    };
    getData();
  }, []);

  useEffect(() => {
    getSubCollection();
    getSubCollectionDescription();
  }, []);

  useEffect(() => {
    isPageCorrect();
    setIsLoading(false);
  }, [isPageCorrect]);

  const loadingScreen = <p>Loading...</p>;

  
  function isPageCorrect() {
    if (viewerProperties.custom_viewer) {
      if (viewerProperties.viewer_name === "Maccaturo") {
        if (renderCount <= 1) {
          updateDataView();
          let count = renderCount + 1;
          setRenderCount(count);
        }
        setIsCorrectViewer(true);
      }
    } else {
      setIsCorrectViewer(false);
    }
  }

  function prevGlb(event) {
    setNumberStepHandler(false);
    const modelViewer = document.querySelector("model-viewer");

    switch (numberStep) {
      case 0:
        setAnimationName("CLOSED");
        setTimeout(() => {
          modelViewer.play();
          setTimeout(() => {
            modelViewer.pause();
            modelViewer.currentTime = 1.87;
          }, 1750);
        }, 1000);
        break;
      case 1:
        setNumberStep(0);
        break;
      case 2:
        break;
      default:
        break;
    }
  }

  function nextGlb(event) {
    event.preventDefault();
    const modelViewer = document.querySelector("model-viewer");
    setNumberStepHandler(true);
  
    switch (numberStep) {
      default:
        break;
      case 1:
        setAnimationName("OPEN");
        setTimeout(() => {
          modelViewer.play();
          setTimeout(() => {
            modelViewer.pause();
            modelViewer.currentTime = 2.44;

          }, 2400.0);
        }, 1000);
        break;
      case 2:
        modelViewer.pause();
        break;
      case 3:
        break;
    }
  }

  function setNumberStepHandler(isNext) {
    if (isNext) {
      setNumberStep((numberStep += 1));
    } else {
      setNumberStep((numberStep -= 1));
    }
  }

  function changeStepFagiolo(event){
    event.preventDefault();
    setNumberStep(3);
  }

  let customPageFound = !isLoading && isCorrectViewer;

  function camera() {
    if (typeof arrayGlb[numberStep].camera_orbit === 'undefined') {
      arrayGlb[numberStep].camera_orbit = "0deg 40deg 101%";
    }
    return arrayGlb[numberStep].camera_orbit;
  }

  function isFacebookBroswer(){
    let ua = navigator.userAgent || navigator.vendor || window.opera ;
    //return true;
    return (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1);
  }


  const isFirefox = typeof InstallTrigger !== 'undefined';

  return (
    <div>
      {isLoading && loadingScreen}
      {( ( customPageFound && !navigator.userAgent.match(/FBAV/i) ) || ( customPageFound && navigator.userAgent.match(/(iPod|iPhone|iPad)/) ) ) && (
        
        <Fragment>
          <div className={style.container}>
            <MaccaturoModelViewer
              text={desc}
              cameraOrbit={camera()}
              url={arrayGlb[numberStep].modelURL}
              alt={viewerProperties.alt}
              autoRotate={arrayGlb[numberStep].auto_rotate}
              cameraControls={arrayGlb[numberStep].camera_controls}
              disableZoom={arrayGlb[numberStep].disable_zoom}
              speedRotate={`${viewerProperties.speed_rotate} deg`}
              lightExposition={viewerProperties.exposure}
              shadowIntensity={viewerProperties.shadow_intensity}
              shadowSmooth={viewerProperties.shadow_softness}
              // getPath={viewerProperties}
              //  autoplay={viewerProperties.autoplay}
              animationName={animationName}
              rotationPerSecond={arrayGlb[numberStep].rotation_per_second}
              QRCode={viewerProperties.qr_code}
              backgoundImage={viewerProperties.background_image}
              poster={arrayGlb[numberStep].poster}
              step={numberStep}
              numberBox={viewerProperties.number_box}
              ios={arrayGlb[numberStep].ios}
              setNumberStep={changeStepFagiolo}
              scale={arrayGlb[numberStep].scale}
              buttonBox={viewerProperties.title_box_element}
              isFacebook = {isFacebookBroswer()}
            >
              <MaccaturoViewerMenu
                numberStep={numberStep}
                prevGlbModel={prevGlb}
                nextGlbModel={nextGlb}
                maxGlbNumber={arrayGlb.length}
                buttonStep3={viewerProperties.title_box_element}
                isFacebook={navigator.userAgent.match(/FBAV/i)}
              ></MaccaturoViewerMenu>
            </MaccaturoModelViewer>
          </div>
        </Fragment>
      )}
      {navigator.userAgent.match(/FBAV/i)  && (
      <div className={style.container}>
       <div className={style["model-container"]} style={{ backgroundImage: `url(${arrayGlb[numberStep]?.poster})` }}>
       <p>This Broswer not support 3D/AR View</p>
       <MaccaturoRightBar
         numberBox={viewerProperties?.number_box}
         numberStep={changeStepFagiolo}
         desc={desc}
         boxTitle={viewerProperties?.title_box_element}
       />
       </div>
       </div>
      )}
      {!customPageFound && <p>.</p>}
       
    </div>
  );
}

export default Maccaturo;
