import styles from "./BulgariRightBar.module.css";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faSeedling,
  faExclamationTriangle,
  faInfo,
  faArrowAltCircleLeft,
  faBoxOpen,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import Modal from "../../UI/Modal";
import BackDrop from "../../UI/BackDrop";
import i18n from "../../utility/i18n";
import logoAr from "../../../icon/AR.png";
import {useSelector, useDispatch} from 'react-redux';
import {authActions} from '../../utility/redux/lang';

function BulgariRightBar(props) {
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSecondModalOpen, setIsSecondModalOpen] = useState(false);
  const dispatch = useDispatch();
  const lang = useSelector(state => state.lang.lang);

  const lista = [];

  function displayModalHandler() {
    setIsModalOpen(!isModalOpen);
  }

  function displaySecondModalHandler() {
    setIsSecondModalOpen(!isSecondModalOpen);
  }

  function changeLanguage(ln) {
    return () => {
      dispatch(authActions.changeLang(ln));
    };
  }

  function openEmail(){
    window.open('mailto:hello@spaarkly.com?subject=Informazioni Spaarkly') 
  }

  return (
    <div>
      {isModalOpen && (
        <Modal isLarge={true}>
          <div className={styles["info-container"]}>
            <div className={styles["info-fagiolo-container-one-option"]}>
              <h3 className={styles["modal-title"]}>
                {" "}
                3d-Ar viewer by Spaarkly{" "}
              </h3>
              <div className={styles["paragrafo-modale-option"]}>
              Personalizza e mostra i tuoi prodotti 3d e AR con il visualizzatore web Spaarkly. 
              </div>
              <div className={styles["paragrafo-modale-option"]}>
               
                 Visualizzatore per uno o più prodotti
                  Menu oggetti 3d personalizzabili. 

              </div>
              <div className={styles["paragrafo-modale-option"]}>
                
                 Personalizza luci, texture, materiali.
Transizioni animate in ingresso e uscita
Hotspot per informazioni aggiuntive
Inserisci pulsanti, link e modali informative
{" "}
              </div>
              <div className={styles["paragrafo-modale-option"]}>
                
                
              Visualizza animazioni degli oggetti 3d-Ar
Qrcode personalizzato per realtà aumentata
Compressione degli oggetti 3d e texture
Compatibile con tutte le web app e siti web (iframe)

              </div>
              <button className={styles["menu-button"]} onClick={openEmail} >
              Contatti{" "}
                  <FontAwesomeIcon size="lg" color="#ffffff" />
                </button>
            </div>
            <div className={styles["info-fagiolo-container-two-option"]}>
            <div className={styles["image-container"]}>
                {/* <img
                  className={styles["modal-image-two"]}
                  src="https://ar3d.spaarkly.it/model3d/back_spaarkly.png"
                  alt="modal img"
                ></img> */}
              </div>
            </div>
          </div>
        </Modal>
      )}
      {isModalOpen && <BackDrop onCancel={displayModalHandler}></BackDrop>}
      <button className={styles["info-button-bulgari"]} onClick={displayModalHandler}>
        <FontAwesomeIcon icon={faInfoCircle} size="lg" />
      </button>

      
    </div>
  );
}

export default BulgariRightBar;
