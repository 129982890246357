import { createSlice } from '@reduxjs/toolkit';

const initialAuthState = {
  lang: 'it',
};

const authSlice = createSlice({
  name: 'authentication',
  initialState: initialAuthState,
  reducers: {
    changeLang(state, action) {
      state.lang = action.payload;
    }
  },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;