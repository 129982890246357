import { Route, Switch, Link } from "react-router-dom";
import TokenViewer from "./components/utility/TokenViewer";
import { BrowserRouter as Router } from "react-router-dom";
import TokenMaccaturo from "./components/utility/TokenMaccaturo";
import TokenBulgari from "./components/utility/TokenBulgari"

import TokenGiovannaQuaratino from "./components/utility/TokenGiovannaQuaratino";


function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact>
          {/* <Link to="/custom-view/Maccaturo/SQOPgnVDVW66skxfFW4O">Redirect </Link> /
          <Link to="/custom-view/Maccaturo/TBpxVaN5iSzZv17YzMLp"> Redirect2 </Link> / */}
          <Link to="/custom-view/SpaarklyBox/j5DqzOCpLSknS5VjNSbo"> Spaarkly Box </Link> /
          {/* <Link to="/custom-view/Maccaturo/IeJ6lyI09hFWKXOPtlfZ"> Redirect4 </Link> / */}
        </Route>
      </Switch>
      <Switch>
        <Route path="/3d-view/:id" component={TokenViewer} />
      </Switch>
      <Switch>
        <Route path="/custom-view/SpaarklyBox/:id" component={TokenMaccaturo} />
      </Switch>
      <Switch>
         <Route path="/custom-view/Bulgari/:id" component={TokenBulgari} />
      </Switch>
      
      <Switch>
      <Route path="/custom-view/GiovannaQuaratino/:id" component={TokenGiovannaQuaratino} />
      </Switch>
    </Router>
  );
}

export default App;
