import { useParams } from "react-router-dom";
import Maccaturo from "../../pages/Maccaturo/Maccaturo";

function TokenMaccaturo(props) {
  const { id: idToken } = useParams();

  return (
    <div>
      <Maccaturo token={idToken}></Maccaturo>
    </div>
  );
}

export default TokenMaccaturo;
