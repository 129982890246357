import { useParams } from "react-router-dom";
import GiovannaQuaratino from "../../pages/giovannaQuaratino/GiovannaQuaratino";

function TokenGiovannaQuaratino(props) {
  const { id: idToken } = useParams();

  return (
    <div>
      <GiovannaQuaratino token={idToken}></GiovannaQuaratino>
    </div>
  );
}

export default TokenGiovannaQuaratino;