import React, { useState, createRef } from "react";
import { Link } from "react-router-dom";
import style from "./MaccaturoModelViewer.module.css";
import MaccaturoRightBar from "./MaccaturoRightBar";
import firebase from "../../utility/firebase";
import logoAr from "../../../icon/AR.png";
import Modal from "../../UI/Modal";
import BackDropTransparent from "../../UI/BackDropTransparent";
import { isMobile } from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import i18n from "../../utility/i18n";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import BulgariRightBar from "../Bulgari/BulgariRightBar";

function MaccaturoModelViewer(props) {
  const MODEL_INSTANCE = {
    ref: props.fowardRef,
    src: props.url,
    alt: props.alt,
    cameraOrbit: props.cameraOrbit,
    cameraControls: props.cameraControls,
    autoRotate: props.autoRotate,
    disableZoom: props.disableZoom,
    lightExposition: props.lightExposition,
    shadowSmooth: props.shadowSmooth,
    shadowIntensity: props.shadowIntensity,
    rotationPerSecond: props.rotationPerSecond,
    animationName: props.animationName,
    poster: props.poster,
    ios: props.ios,
    numberS: props.setNumberStep,
    scale: props.scale,
    text: props.text,
  };

  const lang = useSelector((state) => state.lang.lang);

  const [hideButton, setHideButton] = useState(false);
  const [hideButton2, setHideButton2] = useState(false);
  const [hideButton3, setHideButton3] = useState(false);

  const ref = createRef();
  const { t } = useTranslation();
  const lista = [];

  function castValues(value) {
    return !value ? null : value;
  }

  const [isModalOpen, setIsModalOpen] = useState(false);

  function displayModalHandler() {
    setIsModalOpen(!isModalOpen);
  }

  function camera() {
    if (MODEL_INSTANCE.cameraOrbit === null) {
      MODEL_INSTANCE.cameraOrbit = "0deg 40deg 101%";
    }
    return MODEL_INSTANCE.cameraOrbit;
  }

  function hotspotButtonHandler(event) {
    event.preventDefault();
    setHideButton(!hideButton);
    setHideButton2(false);
    setHideButton3(false);
    ref.current.autoRotate = false;
  }
  function hotspotButtonHandler2(event) {
    event.preventDefault();
    setHideButton2(!hideButton2);
    setHideButton(false);
    setHideButton3(false);
    ref.current.autoRotate = false;
  }
  function hotspotButtonHandler3(event) {
    event.preventDefault();
    setHideButton3(!hideButton3);
    setHideButton2(false);
    setHideButton(false);
    ref.current.autoRotate = false;
  }



  return (
    <div
      className={style["model-container"]}
      // style={{ backgroundImage: `url("https://firebasestorage.googleapis.com/v0/b/d-viewer-aa877.appspot.com/o/img%2FMaccaturo%2FProgetto%20senza%20titolo%20(3).gif?alt=media&token=daaa8fb8-bcae-4d6c-af9d-217a1f70a021"` }}
    >
      {(props.step === 0 || props.step === 1) && (
        <model-viewer
          src={props.url}
          ios-src={MODEL_INSTANCE.ios}
          ref={MODEL_INSTANCE.ref}
          //environment-image="https://ar3d.spaarkly.it/model3d/sunflowers_1k.hdr"
          alt={MODEL_INSTANCE.alt}
          camera-controls={castValues(MODEL_INSTANCE.cameraControls)}
          auto-rotate={castValues(MODEL_INSTANCE.autoRotate)}
          disable-zoom={castValues(MODEL_INSTANCE.disableZoom)}
          exposure={MODEL_INSTANCE.lightExposition}
          shadow-intensity={MODEL_INSTANCE.shadowIntensity}
          shadow-sofness={MODEL_INSTANCE.shadowSmooth}
          rotation-per-second={MODEL_INSTANCE.rotationPerSecond}
          camera-orbit={camera()}
          animation-name={MODEL_INSTANCE.animationName}
          ar
          ar-modes="webxr scene-viewer quick-look"
          animation-crossfade-duration="0"
          poster={MODEL_INSTANCE.poster}
          loading="eager"
          quick-look-browsers="safari chrome"
        >
          <div slot="poster">
            <div className={style["div-poster"]}>
            <span className={style["loading-message"]}>
                {" "}
                Loading box...
                {/*<img className={style["img-poster"]} src={MODEL_INSTANCE.poster}></img> */}
              </span>
            <div className={style["loader"]}>
           
             </div>
            
            </div>
          </div>

          {/* <MaccaturoRightBar
            numberBox={props.numberBox}
            numberStep={MODEL_INSTANCE.numberS}
            desc={MODEL_INSTANCE.text}
            boxTitle={props.buttonBox}
          /> */}
          <BulgariRightBar></BulgariRightBar>

          {isModalOpen && (
            <Modal isSmall={false}>
              <div>
                <div className={style["container-model-ar"]}>
                  <div className={style["text-modal-view"]}>
                    Try this experience in <br />
                    <span>Argumented Reality</span>
                  </div>
                </div>

                <img
                  className={style["qr-code"]}
                  src={props.QRCode}
                  alt="qr code"
                ></img>
                <div className={style["container-model-ar"]}>
                  <p className={style["by-spaarkly"]}>
                    {" "}
                    by{" "}
                    <a
                      href="https://www.spaarkly.it/it/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Spaarkly
                    </a>
                  </p>
                </div>
              </div>
            </Modal>
          )}
          {isModalOpen && !isMobile && (
            <BackDropTransparent
              onCancel={displayModalHandler}
            ></BackDropTransparent>
          )}
          {isMobile ? (
            <button slot="ar-button" className={style["ar-button"]}>
              <img src={logoAr} alt="ar" />
            </button>
          ) : (
            <div className={["button-container-ar"]}>
              <Link
                to="#"
                onClick={displayModalHandler}
                className={style["ar-button"]}
              >
                <img src={logoAr} alt="ar" />
              </Link>
            </div>
          )}

          {props.children}
        </model-viewer>
      )}

      {props.step === 2 && (
        <model-viewer
          src={props.url}
          ios-src={MODEL_INSTANCE.ios}
          ref={ref}
          alt={MODEL_INSTANCE.alt}
         // environment-image="https://ar3d.spaarkly.it/model3d/comfy_cafe_1k.hdr"
          camera-controls={castValues(MODEL_INSTANCE.cameraControls)}
          auto-rotate={castValues(MODEL_INSTANCE.autoRotate)}
          disable-zoom={castValues(MODEL_INSTANCE.disableZoom)}
          exposure={MODEL_INSTANCE.lightExposition}
          shadow-intensity={MODEL_INSTANCE.shadowIntensity}
          shadow-sofness={MODEL_INSTANCE.shadowSmooth}
          rotation-per-second={MODEL_INSTANCE.rotationPerSecond}
          camera-orbit={camera()}
          animation-name={MODEL_INSTANCE.animationName}
          ar
          ar-modes="webxr scene-viewer quick-look"
          animation-crossfade-duration="0"
          poster={MODEL_INSTANCE.poster}
          quick-look-browsers="safari chrome"
        >
         <div slot="poster">
            <div className={style["div-poster"]}>
            <span className={style["loading-message"]}>
                {" "}
                Loading box...
                {/*<img className={style["img-poster"]} src={MODEL_INSTANCE.poster}></img> */}
              </span>
            <div className={style["loader"]}>
           
             </div>
            
            </div>
          </div>
          <BulgariRightBar></BulgariRightBar>

          {isModalOpen && (
            <Modal isSmall={false}>
              <div>
                <div className={style["container-model-ar"]}>
                  <div className={style["text-modal-view"]}>
                    Try this experience in <br />
                    <span>Argumented Reality</span>
                  </div>
                </div>

                <img
                  className={style["qr-code"]}
                  src={props.QRCode}
                  alt="qr code"
                ></img>
                <div className={style["container-model-ar"]}>
                  <p className={style["by-spaarkly"]}>
                    {" "}
                    by{" "}
                    <a
                      href="https://www.spaarkly.it/it/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Spaarkly
                    </a>
                  </p>
                </div>
              </div>
            </Modal>
          )}
          {isModalOpen && !isMobile && (
            <BackDropTransparent
              onCancel={displayModalHandler}
            ></BackDropTransparent>
          )}
          {isMobile ? (
            <button slot="ar-button" className={style["ar-button"]}>
              <img src={logoAr} alt="ar" />
            </button>
          ) : (
            <div className={["button-container-ar"]}>
              <Link
                to="#"
                onClick={displayModalHandler}
                className={style["ar-button"]}
              >
                <img src={logoAr} alt="ar" />
              </Link>
            </div>
          )}

          {props.children}
        </model-viewer>
      )}
      {/* {props.step === 3 && (
        <model-viewer
          src={props.url}
          //ios-src={MODEL_INSTANCE.ios}
          ref={ref}
          alt={MODEL_INSTANCE.alt}
          //environment-image="https://ar3d.spaarkly.it/model3d/comfy_cafe_1k.hdr"
          camera-controls={castValues(MODEL_INSTANCE.cameraControls)}
          auto-rotate={castValues(MODEL_INSTANCE.autoRotate)}
          disable-zoom={castValues(MODEL_INSTANCE.disableZoom)}
          exposure={MODEL_INSTANCE.lightExposition}
          shadow-intensity={MODEL_INSTANCE.shadowIntensity}
          shadow-sofness={MODEL_INSTANCE.shadowSmooth}
          rotation-per-second={MODEL_INSTANCE.rotationPerSecond}
          camera-orbit={camera()}
          animation-name={MODEL_INSTANCE.animationName}
          ar
          ar-modes="webxr scene-viewer quick-look"
          animation-crossfade-duration="0"
          poster={MODEL_INSTANCE.poster}
          loading="eager"
        >
        <div slot="poster">
            <div className={style["div-poster"]}>
            <span className={style["loading-message"]}>
                {" "}
                Loading box...
                {<img className={style["img-poster"]} src={MODEL_INSTANCE.poster}></img> }
              </span>
            <div className={style["loader"]}>
           
             </div>
            
            </div>
          </div>
          <button
            className={style.hotspot}
            onClick={hotspotButtonHandler}
            slot="hotspot-territorio"
            data-position={MODEL_INSTANCE.text[0].Content_Two[1].data_position}
            data-normal={MODEL_INSTANCE.text[0].Content_Two[1].data_normal}
            data-visibility-attribute="visible"
          >
            <FontAwesomeIcon icon={faInfoCircle} size="lg" />
            {hideButton === true ? (
              <div
                className={style["annotation"]}
                onClick={() => {}}
                id="hotspot_1"
              >
               
                {lang === "en" && (
                  
                  <div className={style["hotspot-text-style"]}>
                    <h2>X</h2>
                  <h3 className={style["modal-sub-title"]}>
                      {" "}
                      {MODEL_INSTANCE.text[0].Content_Two[1].titolo_en}{" "}
                    </h3>
                    <p className={style["paragrafo-modale-2"]}>
                      {MODEL_INSTANCE.text[0].Content_Two[1].desc_en}
                    </p>
                  </div>
                )}
                {lang === "it" && (
                  <div className={style["hotspot-text-style"]}>
                    <h2>X</h2>

                  <h3 className={style["modal-sub-title"]}>
                      {" "}
                      {MODEL_INSTANCE.text[0].Content_Two[1].titolo_it}{" "}
                    </h3>
                    <p className={style["paragrafo-modale-2"]}>
                      {MODEL_INSTANCE.text[0].Content_Two[1].desc_it}
                    </p>
                  </div>
                )}

                {lang === "en" && (
                  <div className={style["hotspot-text-style"]}>

                  <h3 className={style["modal-sub-title"]}>
                      {MODEL_INSTANCE.text[0].Content_Two[2].titolo_en}
                    </h3>
                    <p className={style["paragrafo-modale-2"]}>
                      {MODEL_INSTANCE.text[0].Content_Two[2].desc_en}
                    </p>
                  </div>
                )}
                {lang === "it" && (
                  <div className={style["hotspot-text-style"]}>

                  <h3 className={style["modal-sub-title"]}>
                      {MODEL_INSTANCE.text[0].Content_Two[2].titolo_it}
                    </h3>
                    <p className={style["paragrafo-modale-2"]}>
                      {MODEL_INSTANCE.text[0].Content_Two[2].desc_it}
                    </p>
                  </div>
                )}

              </div>
            ) : (
              <div className={style["hide-annotation"]} id="hotspot_3"></div>
            )}
          </button>

          <button
            className={style.hotspot}
            onClick={hotspotButtonHandler2}
            slot="hotspot-aspetto"
            data-position={MODEL_INSTANCE.text[0].Content_Two[3].data_position}
            data-normal={MODEL_INSTANCE.text[0].Content_Two[3].data_normal}
            data-visibility-attribute="visible"
          >
            <FontAwesomeIcon icon={faInfoCircle} size="lg" />
            {hideButton2 === true ? (
              <div
                className={style["annotation"]}
                onClick={() => {}}
                id="hotspot_2"
              >
                
                {lang === "it" && (
                  <div className={style["hotspot-text-style"]}>
                                          <h2>X</h2>

                    <h3 className={style["modal-sub-title"]}>{MODEL_INSTANCE.text[0].Content_Two[3].titolo_it}</h3>
                    {MODEL_INSTANCE.text[0].Content_Two[3].tipo === "lista" ? (
                      <ul>
                        {MODEL_INSTANCE.text[0].Content_Two[3].array_it.forEach(
                          (element) => {
                            lista.push(<li key={Math.random()}>{element}</li>);
                          }
                        )}
                        {lista}
                      </ul>
                    ) : (
                      <p>{MODEL_INSTANCE.text[0].Content_Two[3].desc_it}</p>
                    )}
                  </div>
                )}
                {lang === "en" && (
                  <div className={style["hotspot-text-style"]}>
                    <h2>X</h2>

                  <h3 className={style["modal-sub-title"]}>{MODEL_INSTANCE.text[0].Content_Two[3].titolo_en}</h3>
                    {MODEL_INSTANCE.text[0].Content_Two[3].tipo === "lista" ? (
                      <ul>
                        {MODEL_INSTANCE.text[0].Content_Two[3].array_en.forEach(
                          (element) => {
                            lista.push(<li key={Math.random()}>{element}</li>);
                          }
                        )}
                        {lista}
                      </ul>
                    ) : (
                      <p>{MODEL_INSTANCE.text[0].Content_Two[3].desc_en}</p>
                    )}
                  </div>
                )}

                {lang === "it" && (
                  <div className={style["hotspot-text-style"]}>

                  <h3 className={style["modal-sub-title"]}>
                      {MODEL_INSTANCE.text[0].Content_Two[4].titolo_it}
                    </h3>
                    {MODEL_INSTANCE.text[0].Content_Two[4].tipo === "lista" ? (
                      <ul>
                        {MODEL_INSTANCE.text[0].Content_Two[4].array_it.forEach(
                          (element) => {
                            lista.push(<li key={Math.random()}>{element}</li>);
                          }
                        )}
                        {lista}
                      </ul>
                    ) : (
                      <p>{MODEL_INSTANCE.text[0].Content_Two[4].desc_it}</p>
                    )}
                  </div>
                )}
                {lang === "en" && (
                  <div className={style["hotspot-text-style"]}>

                  <h3 className={style["modal-sub-title"]}>
                      {MODEL_INSTANCE.text[0].Content_Two[4].titolo_en}
                    </h3>
                    {MODEL_INSTANCE.text[0].Content_Two[4].tipo === "lista" ? (
                      <ul>
                        {MODEL_INSTANCE.text[0].Content_Two[4].array_en.forEach(
                          (element) => {
                            lista.push(<li key={Math.random()}>{element}</li>);
                          }
                        )}
                        {lista}
                      </ul>
                    ) : (
                      <p>{MODEL_INSTANCE.text[0].Content_Two[4].desc_en}</p>
                    )}
                  </div>
                )}

              </div>
            ) : (
              <div className={style["hide-annotation"]} id="hotspot_3"></div>
            )}

            
          </button>

          <button
            className={style.hotspot}
            onClick={hotspotButtonHandler3}
            slot="hotspot-proprieta"
            data-position={MODEL_INSTANCE.text[0].Content_Two[5].data_position}
            data-normal={MODEL_INSTANCE.text[0].Content_Two[5].data_normal}
            data-visibility-attribute="visible"
          >
            <FontAwesomeIcon icon={faInfoCircle} size="lg" />
            {hideButton3 === true ? (
              <div
                className={style["annotation"]}
                onClick={() => {}}
                id="hotspot_3"
              >
                {lang === "it" && (
                  <div className={style["hotspot-text-style"]}>
                                        <h2>X</h2>

                  <h3 className={style["modal-sub-title"]}>
                      {MODEL_INSTANCE.text[0].Content_Two[5].titolo_it}
                    </h3>
                    {MODEL_INSTANCE.text[0].Content_Two[5].tipo === "lista" ? (
                      <ul>
                        {MODEL_INSTANCE.text[0].Content_Two[5].array_it.forEach(
                          (element) => {
                            lista.push(<li key={Math.random()}>{element}</li>);
                          }
                        )}
                        {lista}
                      </ul>
                    ) : (
                      <p>{MODEL_INSTANCE.text[0].Content_Two[5].desc_it}</p>
                    )}
                  </div>
                )}
                {lang === "en" && (
                  <div className={style["hotspot-text-style"]}>
                                        <h2>X</h2>

                  <h3 className={style["modal-sub-title"]}>
                      {MODEL_INSTANCE.text[0].Content_Two[5].titolo_en}
                    </h3>
                    {MODEL_INSTANCE.text[0].Content_Two[5].tipo === "lista" ? (
                      <ul>
                        {MODEL_INSTANCE.text[0].Content_Two[5].array_en.forEach(
                          (element) => {
                            lista.push(<li key={Math.random()}>{element}</li>);
                          }
                        )}
                        {lista}
                      </ul>
                    ) : (
                      <p>{MODEL_INSTANCE.text[0].Content_Two[5].desc_en}</p>
                    )}
                  </div>
                )}

                {lang === "it" && (
                  <div className={style["hotspot-text-style"]}>
                  <h3 className={style["modal-sub-title"]}>{MODEL_INSTANCE.text[0].Content_Two[6].titolo_it}</h3>
                    {MODEL_INSTANCE.text[0].Content_Two[6].tipo === "lista" ? (
                      <ul>
                        {MODEL_INSTANCE.text[0].Content_Two[6].array_it.forEach(
                          (element) => {
                            lista.push(<li key={Math.random()}>{element}</li>);
                          }
                        )}
                        {lista}
                      </ul>
                    ) : (
                      <p>{MODEL_INSTANCE.text[0].Content_Two[6].desc_it}</p>
                    )}
                  </div>
                )}
                {lang === "en" && (
                  <div className={style["hotspot-text-style"]}>
                    <h3 className={style["modal-sub-title"]}>{MODEL_INSTANCE.text[0].Content_Two[6].titolo_en}</h3>
                    {MODEL_INSTANCE.text[0].Content_Two[6].tipo === "lista" ? (
                      <ul>
                        {MODEL_INSTANCE.text[0].Content_Two[6].array_en.forEach(
                          (element) => {
                            lista.push(<li key={Math.random()}>{element}</li>);
                          }
                        )}
                        {lista}
                      </ul>
                    ) : (
                      <p>{MODEL_INSTANCE.text[0].Content_Two[6].desc_en}</p>
                    )}
                  </div>
                )}
               
              </div>
            ) : (
              <div className={style["hide-annotation"]} id="hotspot_3"></div>
            )}
          </button>

          <MaccaturoRightBar desc={MODEL_INSTANCE.text} boxTitle={props.buttonBox} />

          {isModalOpen && (
            <Modal isSmall={false}>
              <div>
                <div className={style["container-model-ar"]}>
                  <div className={style["text-modal-view"]}>
                    Try this experience in <br />
                    <span>Argumented Reality</span>
                  </div>
                </div>

                <img
                  className={style["qr-code"]}
                  src={props.QRCode}
                  alt="qr code"
                ></img>
                <div className={style["container-model-ar"]}>
                  <p className={style["by-spaarkly"]}>
                    {" "}
                    by{" "}
                    <a
                      href="https://www.spaarkly.it/it/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Spaarkly
                    </a>
                  </p>
                </div>
              </div>
            </Modal>
          )}
          {isModalOpen && !isMobile && (
            <BackDropTransparent
              onCancel={displayModalHandler}
            ></BackDropTransparent>
          )}
          {isMobile ? (
            <button slot="ar-button" className={style["ar-button"]}>
              <img src={logoAr} alt="ar" />
            </button>
          ) : (
            <div className={["button-container-ar"]}>
              <Link
                to="#"
                onClick={displayModalHandler}
                className={style["ar-button"]}
              >
                <img src={logoAr} alt="ar" />
              </Link>
            </div>
          )}

          {props.children}
        </model-viewer>
      )} */}
    </div>
  );
}

export default MaccaturoModelViewer;
