import { useParams } from "react-router-dom";
import Bulgari from "../../pages/Bulgari/Bulgari";

function TokenBulgari(props) {
  const { id: idToken } = useParams();

  return (
    <div>
      <Bulgari token={idToken}></Bulgari>
    </div>
  );
}

export default TokenBulgari;
