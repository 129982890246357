import style from "./Bulgari.module.css";
import { useEffect, useState, Fragment, createRef } from "react";
import BulgariRightBar from "../../components/layout/Bulgari/BulgariRightBar";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import logoAr from "../../icon/AR.png";
import add from "../../icon/add.png"
import Modal from "../../components/UI/Modal";
import BackDropTransparent from "../../components/UI/BackDropTransparent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faSeedling,
  faExclamationTriangle,
  faInfo,
  faArrowAltCircleLeft,
  faBoxOpen,
  faEye,
} from "@fortawesome/free-solid-svg-icons";

/*eslint-disable */

function Bulgari(props) {

  const [cameraOrbit, setCameraOrbit] = useState("360deg 78.46deg auto ");
  const [cameraTarget, setCameraTarget] = useState("-0.0m -0.0m 0.027m ");
  const [exposure, setExposure] = useState(0.2);
  const [cameraControl, setcameraControl] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [hideButton, setHideButton] = useState(false);
  const [hideButton2, setHideButton2] = useState(false);
  const [hideButton3, setHideButton3] = useState(false);
  const [hideButton4, setHideButton4] = useState(false);

  const [isHotspot1Visible, setViewHotspot1] = useState(false);
  const [isHotspot2Visible, setViewHotspot2] = useState(false);
  const [isHotspot3Visible, setViewHotspot3] = useState(false);
  const [isHotspot4Visible, setViewHotspot4] = useState(false);
  const [isArView, setArView] = useState(false);

  const ref = createRef();


  function disableHotspot(){
    setViewHotspot1(false);
    setViewHotspot2(false);
    setViewHotspot3(false);
    setViewHotspot4(false);
    setArView(true);
    console.log("qui");
  }


  function hotspotButtonHandler(event) {
    event.preventDefault();
    setHideButton(!hideButton);
    setHideButton2(false);
    setHideButton3(false);
    setHideButton4(false);
  }
  function hotspotButtonHandler2(event) {
    event.preventDefault();
    setHideButton2(!hideButton2);
    setHideButton(false);
    setHideButton3(false);
    setHideButton4(false);

  }
  function hotspotButtonHandler3(event) {
    event.preventDefault();
    setHideButton3(!hideButton3);
    setHideButton2(false);
    setHideButton(false);
    setHideButton4(false);

  }
  function hotspotButtonHandler4(event) {
    event.preventDefault();
    setHideButton3(false);
    setHideButton2(false);
    setHideButton(false);
    setHideButton4(!hideButton4);

  }
  function displayModalHandler() {
    setIsModalOpen(!isModalOpen);
  }
    const MODEL_INSTANCE = {
        src: "https://ar3d.spaarkly.it/model3d/AN852405final.glb",
        cameraControls: true,
        autoRotate: false, 
        
        // alt: props.alt,
        //cameraTarget: "-0.0m -0.0m 0.02m"
        // cameraControls: props.cameraControls,
        // autoRotate: props.autoRotate,
        // disableZoom: props.disableZoom,
        // lightExposition: props.lightExposition,
        // shadowSmooth: props.shadowSmooth,
        // shadowIntensity: props.shadowIntensity,
        // rotationPerSecond: props.rotationPerSecond,
        // animationName: props.animationName,
        // poster: props.poster,
        // ios: props.ios,
        // numberS: props.setNumberStep,
        // scale: props.scale,
        // text: props.text,
      };

      function castValues(value) {
        return !value ? null : value;
      }


      let range = 0;
      let passo = 3;
      let temp = 100;
      let gradi = 120;
      let velocità = 1;
      let initialExposure = 0.2;
      useEffect(() => {
        setInterval(()=> {
         

          if(range < 110){
            range = range + passo;
            gradi = 120 + range;
            setCameraOrbit(`${gradi}deg 84.08deg auto`);
          }
          if (range >= 110 && range <= 260){
            velocità = velocità++;
            range = range + (passo * (velocità*4));
            gradi = 120 + range;
            setCameraOrbit(`${gradi}deg 84.08deg auto`);
        
          }
          if(range >= 260 && range < 770){
            range = range + (passo * (velocità*16));
            gradi = 120 + range;
            setCameraOrbit(`${gradi}deg 84.08deg auto`);
            setCameraTarget("-0.0m -0.0m 0.0m");
          }
          if(range > 620 && initialExposure <0.8 ){
            initialExposure = initialExposure + 0.05;
            setExposure(initialExposure);
            
          }
          if(range > 620){
            setTimeout(()=>{
              setcameraControl(true);
              setTimeout(()=>{
                setViewHotspot4(true);
                setTimeout(()=>{
                  setViewHotspot3(true)
                  setTimeout(()=>{
                    setViewHotspot2(true);
                    setTimeout(()=>{
                      setViewHotspot1(true);
                    },400)
                  },400)
                },400)
              },400)
            },2000)
          }
        
         
          
        },temp) 
        
        
      }, []);
        
    



    return(
        <div className={style.container}>
        <div
        className={style["model-container"]}
        style={{ backgroundImage: `url(https://ar3d.spaarkly.it/model3d/Bulgari_logo.svg.png)` }}

         >
             <model-viewer
             src={MODEL_INSTANCE.src}
             id = "bulgari-viewer"
        //   ios-src={MODEL_INSTANCE.ios}
            // environment-image="https://ar3d.spaarkly.it/model3d/studio_small_08_1k.hdr"
        //   alt={MODEL_INSTANCE.alt}
             camera-controls={castValues(cameraControl)}
             auto-rotate={castValues(MODEL_INSTANCE.autoRotate)}
             camera-target={cameraTarget}
        //   disable-zoom={castValues(MODEL_INSTANCE.disableZoom)}
        //   exposure={MODEL_INSTANCE.lightExposition}
        //   shadow-intensity={MODEL_INSTANCE.shadowIntensity}
        //   shadow-sofness={MODEL_INSTANCE.shadowSmooth}
        //   rotation-per-second={MODEL_INSTANCE.rotationPerSecond}
             camera-orbit={cameraOrbit}
             exposure={exposure}
        //   animation-name={MODEL_INSTANCE.animationName}
             ar
             ar-modes="scene-viewer webxr quick-look"
        //   animation-crossfade-duration="0"
        //   poster={MODEL_INSTANCE.poster}
             loading="eager"
            // xr-environment

        >

          <BulgariRightBar></BulgariRightBar>

          {isModalOpen && (
            <Modal isSmall={false}>
              <div>
                <div className={style["container-model-ar"]}>
                  <div className={style["text-modal-view"]}>
                    Try this experience in <br />
                    <span>Argumented Reality</span>
                  </div>
                </div>

                <img
                  className={style["qr-code"]}
                  src="https://ar3d.spaarkly.it/model3d/qr-bulgari.png"
                  alt="qr code"
                ></img>
                <div className={style["container-model-ar"]}>
                  <p className={style["by-spaarkly"]}>
                    {" "}
                    by{" "}
                    <a
                      href="https://www.spaarkly.it/it/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Spaarkly
                    </a>
                    <br>this Device not support AR Mode</br>
                  </p>
                </div>
              </div>
            </Modal>
          )}
          {isModalOpen && !isMobile && (
            <BackDropTransparent
              onCancel={displayModalHandler}
            ></BackDropTransparent>
          )}
          {isMobile ? (
            <button slot="ar-button" className={style["ar-button"]} onClick={()=>disableHotspot()}>
              <img src={logoAr} alt="ar" onClick={()=>disableHotspot()}/>
            </button>
          ) : (
            <div className={["button-container-ar"]}>
              <Link
                to="#"
                onClick={displayModalHandler}
                className={style["ar-button"]}
              >
                <img src={logoAr} alt="ar" />
              </Link>
            </div>
          )}
         
         
         {isHotspot1Visible &&(
            <button className={style.hotspot} slot="hotspot-2" 
            data-position="0.008628859863332478m 0.0065202758126467995m 0.00462945643812418m" 
            data-normal="0m 0m 1m" data-visibility-attribute="visible" onClick={hotspotButtonHandler}
            >
                <img src={add}></img>
           
            {hideButton === true ? (
                <div
                  className={style["annotation"]}
                  onClick={() => {}}
                  id="hotspot_2"
                >
  
                Usa gli hotspot per aggiungere informazioni dettagliate sui tuoi prodotti
                  </div>
            ) : (
              <div className={style["hide-annotation"]} id="hotspot_3"></div>
            )}
             </button>
             
         )}
         {isHotspot2Visible && (
            <button className={style.hotspot} slot="hotspot-3" 
            data-position="-0.010047027817622851m 0.005510057328198863m -0.004390310760718134m" 
            data-normal="-0.8458046204322733m 0.46529318973435463m -0.2609919378874524m" 
            data-visibility-attribute="visible" onClick={hotspotButtonHandler3}>
               <img src={add}></img>         
               {hideButton3 === true ? (
                <div
                  className={style["annotation"]}
                  onClick={() => {}}
                  id="hotspot_3"
                >
  
                Crea Pulsanti per generare animazioni o link            
             </div>
            ) : (
              <div className={style["hide-annotation"]} id="hotspot_3"></div>
            )}
             </button>
         )}

          {isHotspot3Visible && (
            <button className={style.hotspot} slot="hotspot-1" 
           data-position="0.006218490838080031m -0.008199601989914627m -0.004629456903785464m" 
           data-normal="0m 0m -1m" data-visibility-attribute="visible" onClick={hotspotButtonHandler2}>
            <img src={add}></img>         
             {hideButton2 === true ? (
              <div
                className={style["annotation"]}
                onClick={() => {}}
                id="hotspot_1"
              >

            Rendi i tuoi prodotti in la tua scena con il background e skybox enviroment       
                </div>
          ) : (
            <div className={style["hide-annotation"]} id="hotspot_3"></div>
          )}
           </button>

          )}

          {isHotspot4Visible && (
            <button className={style.hotspot} slot="hotspot-4" data-position="0.006074444120877225m 0.007830144061273511m -0.004574066311789835m" 
           data-normal="-0.5582163611775641m -0.6368472457838422m -0.5318083110023891m"
            data-visibility-attribute="visible"  onClick={hotspotButtonHandler4}>
            <img src={add}></img>         
             {hideButton4 === true ? (
              <div
                className={style["annotation"]}
                onClick={() => {}}
                id="hotspot_4"
              >

            Crea e personalizza il menu per le variazioni dei prodotti            
            </div>
          ) : (
            <div className={style["hide-annotation"]} id="hotspot_3"></div>
          )}

            </button>
          )}

           

           
        </model-viewer>
        </div>
        </div>
       
    )

}
  
  export default Bulgari;
  